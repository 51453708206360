// index.js (or the main entry point of your app)
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./store/configureStore";
import App from "./Myapp";
import "./index.css"; // Import the CSS file here

let persistor = persistStore(store);

const RootApp = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router >
        <App />
      </Router>
    </PersistGate>
  </Provider>
);

export default RootApp;


