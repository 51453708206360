const button = {
  MuiButton: {
    variants: [
      {
        props: { variant: "menu" },
        style: {
          textTransform: "none",
          border: "1px solid black",
          fontFamily: "Inter",
          backgroundColor: "black",
          width: "100%",
          //   fontSize: FONT.SIZES[12],
          //   fontWeight: FONT.WEIGHT.SEMI_BOLD,
          borderRadius: "25px",
        },
      },
      {
        props: { variant: "conatined" },
        style: {
          fontFamily: "Inter",
          fontSize: "16px",
          lineHeight: "22px",
          padding: "4px 0",
          fontWeight: "400",
          fontStyle: "regular",
          backgroundColor: "#4CB5F5",
        },
      },

      {
        props: { variant: "MyCustomButton2" },
        style: {
          fontFamily: "Inter",
          fontSize: "16px",
          lineHeight: "22px",
          padding: "4px 0",
          fontWeight: "400",
          fontStyle: "regular",
          backgroundColor: "#1d1d1d",
        },
      },
    ],
  },
};

export default button;
