import { FormControl, FormHelperText } from "@mui/material";
import React from "react";
import { MUI } from "../../components/CustomComponents";
import { Formik } from "formik";
import * as Yup from "yup";
const CreateUser = (props) => {
  const { CustomTypography, CustomInput, LoginButton } = MUI;
console.log(props)
  const renderInputField = (
    label,
    name,
    value,
    placeholder,
    handleChange,
    touched,
    error,
    type
  ) => {
    return (
      <FormControl variant="standard" sx={{ marginTop: "4px" }} fullWidth>
        <CustomTypography>{label}</CustomTypography>
        <CustomInput
          error={Boolean(touched && error)}
          name={name}
          value={value}
          type={type ? type : "text"}
          onChange={handleChange}
          placeholder={placeholder}
          size="small"
        />
        <FormHelperText error sx={{ fontFamily: "Inter" }}>
          {touched && error}
        </FormHelperText>
      </FormControl>
    );
  };
  const value = props.value
    ? {
        username: props.value?.username,
        workspace: props.value?.workspace?.name,
        phone: props.value?.phone,
      }
    : {
        username: "",
        workspace: "",
        phone: "",
      };

  return (
    <Formik
      initialValues={value}
      enableReinitialize
      validationSchema={Yup.object().shape({
        username: Yup.string().required("Please enter user name"),
        workspace: Yup.string()
          .required("Please enter user workspace"),
        phone: Yup.string().required(" Please enter phone"),
      })}
      onSubmit={(values) => {
        // handleOnSubmit(values);
        props.handleSubmit(values);
      }}
    >
      {({ errors, handleChange, handleSubmit, touched, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          {renderInputField(
            "User Name*",
            "username",
            values.username,
            "Enter user name",
            handleChange,
            touched.username,
            errors.username
          )}
          {renderInputField(
            "User workspace*",
            "workspace",
            values.workspace,
            "Enter user workspace",
            handleChange,
            touched.workspace,
            errors.workspace
          )}
          {renderInputField(
            "Phone*",
            "phone",
            values.phone,
            "Enter user phone",
            handleChange,
            touched.phone,
            errors.phone
          )}
          {/* <CustomTypography id="demo-simple-select-label">Role*</CustomTypography>
            <FormControl fullWidth>
              <Select
                size="medium"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.role}
                name='role'
                sx={{borderRadius:'8px',fontFamily:'Inter',fontWeight:500, "&:focus": {
                  border: "0"
                }}}
                onChange={handleChange}
              >
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'operator'}>Operator</MenuItem>
                <MenuItem value={'monitor'}>Monitor</MenuItem>
              </Select>
            </FormControl> */}
          <LoginButton
            type="submit"
            sx={{
              fontSize: "14px",
              background: "#0075ff",
              "&:hover": {
                backgroundColor: "#0075ff",
              },
            }}
          >
            {"Update"}
          </LoginButton>
        </form>
      )}
    </Formik>
  );
};

export default CreateUser;
