import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
// import LockIcon from "./../../images/Lock/ThemeLock.jsx;
// import UnLockIcon from "../../images/Lock/ThemeUnlock.jsx";
// import LockIcon from "./../../images/Lock/ThemeLock.jsx";
// import OpenFileIcon from "./../../images/Lock/open.jsx";
import { styled } from '@mui/material/styles';

const PREFIX = 'CreateDialog';

const classes = {
  root: `${PREFIX}-root`,
  inputbase: `${PREFIX}-inputbase`,
  alignCenter: `${PREFIX}-alignCenter`,
};


const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  [`&.${classes.alignCenter}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`&.${classes.inputbase}`]: {
    border: "1px solid #D9D9D9",
    padding: "5px 12px",
    width: "100%",
    borderRadius: "2px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400",
    "&::placeholder": {
      color: "#00000040",
    },
  },
}))
const CreateDialog = (props) => {
  const handleCancel = () => {
    props.handleClose();
  };

  return (
    <Root className={classes.root}>
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "70%",
          position: "relative",
          overflow: "unset",
        },
      }}
      maxWidth={props.title === "Upload" ? "md" : "xs"}
      open={props.open}
    >
      {props.title !== "Important Notice"  && (
        <IconButton
          onClick={handleCancel}
          sx={{
            position: "absolute",
            top: -10,
            right: -10,
            backgroundColor: "#fff",
            zIndex: 1,
            padding:0,
            color:'#0075ff',
            "&:hover":{
            backgroundColor: "#fff",
            color:'#0075ff',
            }
          }}
        >
          <CancelIcon/>
        </IconButton>
      )}
      {props.title === "Upload" ? null : (
        <DialogTitle style={{ position: "relative", zIndex: 0 }}>
          {props.lock?.name || props.fileOpen ? (
            <Box className={classes.alignCenter}>
              <Typography variant="toastTitle" pl={1}>
                {" "}
                {props.title}
              </Typography>
            </Box>
          ) : (
            props.title
          )}
        </DialogTitle>
      )}
      <DialogContent dividers sx={{ borderBottom: "none" }}>
        {props.children}
      </DialogContent>
    </Dialog>
    </Root>
  );
};

export default CreateDialog;
