import React from 'react';
import Login from './container/login/login';
import Homes from './container/home/home'
import Users from './container/users/user'

import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/Layout/DashboardLayout';
const routes = [
    {
        children: [
            { path: '/signin', element: <Login /> },
            { path: '/*', element: <Navigate to="/404"  replace/> }, 
            {
                path: '',
                element: <DashboardLayout />,
                children: [
                    { path: '/dashboard', element: <Homes /> },
                    { path: '/users', element: <Users /> },

                ]
            }
        ]
    }
]





export default routes;

