import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { dashboardApi } from "../../_services/dashboard";
import { crypto } from "../../components/Security/crypto";


const formatChartData = (data) => {
  const labels = Object.keys(data);
  const values = Object.values(data);

  
  return {
    labels: labels,

    datasets: [
      {
        data: values,
        backgroundColor: [
            "rgba(255, 99, 132, 0.8)",   // Red
            "rgba(54, 162, 235, 0.8)",  // Blue
            "rgba(255, 206, 86, 0.8)",  // Yellow
            "rgba(75, 192, 192, 0.8)",  // Teal
            "rgba(153, 102, 255, 0.8)", // Purple
          ],
          hoverBackgroundColor: [
            "rgba(255, 99, 132, 1)",   // Red
            "rgba(54, 162, 235, 1)",   // Blue
            "rgba(255, 206, 86, 1)",   // Yellow
            "rgba(75, 192, 192, 1)",   // Teal
            "rgba(153, 102, 255, 1)",  // Purple
          ],
      },
    ],
  };
};

const UserStatusDoughnutChart = () => {
  const [userData, setUserData] = useState([]);
  const { last_login, ...newData } = userData;
  const newObj = {};

  for (const key in newData) {
    if (newData.hasOwnProperty(key)) {
      const newKey = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
      newObj[newKey] = newData[key];
    }
  }
  const data = formatChartData(newObj);

  useEffect(() => {
    getResponse();
  }, []);
  const getResponse = () => {
    dashboardApi.getDashboardData().then((res) => {
      if (res.status === 200) {    
        const decreptedData = crypto.decryption(res.data.data)   
        setUserData(decreptedData);
      }
    });
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true, // Hide the legend if desired
        position: "right", // Position the legend to the left
        labels: {
          boxWidth: 20,
          padding: 20,
          usePointStyle: true,
        },
      },
    },
  };

  return (
    <div style={{ width: "400px", height: "500px" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default UserStatusDoughnutChart;
