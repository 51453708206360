import axios from "axios";
import { helpers } from "../_helpers";
import { crypto } from "../components/Security/crypto";


export const userService = {
    getAllUsers,
    updateUser,
    updateStatus
};




async function getAllUsers() {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    helpers.setHeader();
    try {
        const data = await axios.get(
            `/cloudzilla/users `,
            requestOptions
        );
        return data;
    } catch (error) {
        return error.response;
    }
}

async function updateUser(id,payloadObject) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    helpers.setHeader();
    const encryptedData = crypto.encryption(payloadObject)
    try {
        const data = await axios.put(
            `/users/profile/${id} `,encryptedData,
            requestOptions
        );
        return data;
    } catch (error) {
        return error.response;
    }
}

async function updateStatus(id,payloadObject) {
    const requestOptions = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    helpers.setHeader();
    const encryptedData = crypto.encryption(payloadObject)
    try {
        const data = await axios.put(
            `/users/${id}/status`,encryptedData,
            requestOptions
        );
        return data;
    } catch (error) {
        return error.response;
    }
}





