import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { userService } from "../../_services/users";
import moment from 'moment'
import { crypto } from "../../components/Security/crypto";
const formatChartData = (data) => {
  // Extract the created_at dates and count the number of users created on each date
  const dateCounts = data.reduce((acc, user) => {
    const date = user.created_at
      ? moment(user.created_at).format('MM/YYYY')
      : null;
    if (date) {
      acc[date] = (acc[date] || 0) + 1;
    }
    return acc;
  }, {});

  // Convert the dateCounts object to arrays for the labels and data
  const labels = Object.keys(dateCounts);
  const chartData = Object.values(dateCounts);
  return {
    labels,
    datasets: [
      {
        label: "Users",
        data: chartData,
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };
};

const LineGraph = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getAllUsers();
  }, []);
  const getAllUsers = () => {
    userService.getAllUsers().then((res) => {
      if (res.status === 200) {
        const decreptedData = crypto.decryption(res.data?.data)
        setData(decreptedData);
      }
    });
  };

  const userData = formatChartData(data);

  return (
      <Line
        data={userData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
          },
        }}
      />
  );
};

export default LineGraph;
