import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Card, CardContent, Typography, Grow } from '@mui/material';
const PREFIX = 'NormalCard';

const classes = {
    root: `${PREFIX}-root`,
    editbtn: `${PREFIX}-editbtn` 
};

const StyledCard = styled(Card)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {    
        '& .MuiCardContent-root':{
            padding:'16px !important'   
        }, 
        position:'relative',     
        backgroundColor: "#ffffff",
        paddingBottom: theme.spacing(2),        
        marginBottom:theme.spacing(3),
        
    },

    [`& .${classes.editbtn}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '4px',
        alignItems: 'center',
        borderBottom: '1px solid #324C90',
        [theme.breakpoints.down('xs')]:{
            padding: theme.spacing(1),
        }
    }
}));

const NormalCard = (props) => {


    return (
        <Grow in={true} style={{transitionDelay:100}}>
        <StyledCard  className={classes.root}>
            <CardContent>
                <Grid container>
                    <Grid item xs={12} >
                        <div className={classes.editbtn}>
                            <div>
                                <Typography style={{fontSize:"1rem", fontWeight:500, color:'grey'}} component="span" variant='h5' color="primary">{props.title}</Typography>
                                {/* <Typography variant='body2' >{props.subtitle}</Typography> */}
                            </div>
                            {props.btn || null}
                        </div>
                        {props.children}
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>
        </Grow>
    );
};

export default NormalCard;
