import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Box,
  IconButton,
  Chip,
  AppBar,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import customStyles from "../../components/Table/index.js";
import { Edit } from "@mui/icons-material";
import CreateDialog from "../../components/Dialog/CreateDialog";
import CreateUser from "./createUser";
import { userService } from "../../_services/users.js";
import Alert from "../../components/alert";
import { ThemeProvider } from "@mui/material/styles";
import GetMuiTheme from "../../components/theme.js";
import AlertDialog from "../../components/Alert/Alert.jsx";
import { crypto } from "../../components/Security/crypto.js";
const User = () => {
  const [toastOpen, setToastOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(false);
  const [data, setData] = useState([]);
  const [severity, setSeverity] = useState(false);
  const [message, setMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    description: "",
  });

  const getMuiTheme = GetMuiTheme();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleClose = () => {
    setOpen(false);
    setToastOpen(false);
    setCurrentRow(false);
    setAlert({
      open: false,
      title: "",
      description: ``,
      row: "",
    });
  };
  const editUser = (row) => {
    console.log(row)
    setCurrentRow(row);
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmit = async (values) => {
    const data = {
      user_details: {
        username: values.username,
        phone: values.phone,
      },
      workspace_details: {
        name: values.workspace,
        workspace_id:currentRow.workspace_id
      },
    };
    try {
      userService.updateUser(currentRow.id, data).then((res) => {
        if (res.status === 200) {
          setOpen(false);
          setCurrentRow(false);
          setMessage("Updated successfully");
          setToastOpen(true);
          setSeverity("success");
          getAllusers();
        } else {
          setMessage(res.data.message);
          setToastOpen(true);
          setSeverity("error");
        }
      });
    } catch (error) {
      setMessage("Something went wrong");
      setToastOpen(true);
      setSeverity("error");
    }
    handleClose();
  };

  useEffect(() => {
    getAllusers();
  }, []);

  // Iterate through the users and sort them into the appropriate array

  const getAllusers = () => {
    userService.getAllUsers().then((res) => {
      if (res.status === 200) {
        const activeUsers = [];
        const inactiveUsers = [];
        // setData(res.data.response);
        const decreptedData = crypto.decryption(res.data.data)
        decreptedData.forEach((user) => {
          if (user.status === 1) {
            activeUsers.push(user);
          } else {
            inactiveUsers.push(user);
          }
          setData({
            activeUsers: activeUsers,
            inactiveUsers: inactiveUsers,
          });
        });
      } else {
        setMessage("Something went wrong");
        setToastOpen(true);
        setSeverity("error");
      }
    });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const viewColumns = [
    {
      name: "Name",
      selector: (row) => row.username,
      sortable: true,
    },

    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Workspace Name",
      selector: (row) => row.workspace?.name,
      sortable: true,
    },
    {
      name: "status",
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => {
        return row.status ? (
          <Chip
            size="small"
            color="success"
            onClick={() => handleConfirm(row)}
            label={"Active"}
          />
        ) : (
          <Chip
            size="small"
            color="error"
            onClick={() => handleConfirm(row)}
            label={"Inactive"}
          />
        );
      },
    },
    {
      name: "Action",
      // sortable: true,
      right: true,
      selector: (row) => row.role,
      cell: (row) => {
        return (
          <Box>
            <IconButton sx={{ padding: "5px" }}>
              <Edit sx={{ color: "grey" }} onClick={() => editUser(row)} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleConfirm = (value) => {
    setAlert({
      open: true,
      title: "Change Status",
      description: `Are you sure want to make ${value.username} as ${
        value.status === 0 || value.status === null ? "Active" : "Inactive"
      }?`,
      row: value,
    });
  };

  const handleStatus = async () => {
    const value = alert.row;
    try {
      const data = {
        status: value.status === 0 || value.status === null ? 1 : 0,
      };
      await userService.updateStatus(value.id, data).then((res) => {
        handleClose();
        if (res.status === 200) {
          getAllusers();
          setMessage("user status successfully");
          setToastOpen(true);
          setSeverity("success");
        } else {
          setMessage(res.data?.message);
          setToastOpen(true);
          setSeverity("error");
        }
      });
    } catch (error) {
      handleClose();
      setMessage("Something went wrong");
      setToastOpen(true);
      setSeverity("error");
    }
  };
  return (
    <Box
      sx={{
        border: data.length > 0 ? "1px solid lightGrey" : "",
        borderRadius: "8px",
        margin: "1rem",
      }}
    >
      <ThemeProvider theme={getMuiTheme}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            style={{
              marginBottom: 24,
              borderBottom: "1px solid #e8e8e8",
            }}
          >
            <Tab label="Active" {...a11yProps(0)} />
            <Tab label="Inactive" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </ThemeProvider>
      <TabPanel value={value} index={0} sx={{ padding: 0 }}>
        <DataTable
          style={{ border: "1px solid grey", padding: 0 }}
          dense
          noDataComponent="No data available"
          pagination
          noBorder
          // sortIcon={
          //   <img style={{ margin: "0px 0 -2px 6px " }} src={SortIcon} alt="" />
          // }
          columns={viewColumns}
          data={data.activeUsers}
          customStyles={customStyles}
          highlightOnHover
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DataTable
          style={{ border: "1px solid grey", padding: 0 }}
          dense
          noDataComponent="No data available"
          pagination
          noBorder
          // sortIcon={
          //   <img style={{ margin: "0px 0 -2px 6px " }} src={SortIcon} alt="" />
          // }
          columns={viewColumns}
          data={data.inactiveUsers}
          customStyles={customStyles}
          highlightOnHover
        />
      </TabPanel>

      <CreateDialog
        open={open}
        title={"Update user"}
        handleClose={handleClose}
        children={<CreateUser value={currentRow} handleSubmit={handleSubmit} />}
      />
      <Alert
        severity={severity}
        message={message}
        open={toastOpen}
        handleClose={() => setToastOpen(false)}
      />
      <AlertDialog alert={alert} handleChange={handleStatus} handleClose={handleClose} />
    </Box>
  );
};

export default User;
