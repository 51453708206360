import {
    Box,
    Container,
    FormControl,
    Card,
    FormHelperText,
  } from "@mui/material";
  import React, { useState } from "react";
  import Logo from "./../../images/home/logo.png";
  import { MUI } from "../../components/CustomComponents";
  import { Formik } from "formik";
  import * as Yup from "yup";
  import { useNavigate } from "react-router-dom";
  import styled from "styled-components";
  import { loginApi } from "../../_services/login";
  import Alert from "../../components/alert";
  import { setToken,loginSuccess } from '../../store/bugs'
  import { connect } from 'react-redux';
  import Page from "../../components/page";
  import Cookies from 'universal-cookie'
import { crypto } from "../../components/Security/crypto";
  
  
  const PREFIX = "NormalCard";
  const classes = {
    container: `${PREFIX}-container`,
    anchor: `${PREFIX}-anchor`,
  };
  
  const Root = styled("div")(({ theme }) => ({
    [`& .${classes.container}`]: {
      height: "100vh",
      alignItems: "center",
      display: "flex",
      background:'#EEF4FC'
    },
  }));
  
  const SignIn = (props) => {
    const { CustomTypography, CustomInput, LoginButton } = MUI;
    const [severity,setSeverity]= useState(false)
    const [message,setMessage]= useState(false)
    const [open,setOpen]= useState(false)
    const [loading,setLoading]= useState(false)
    const cookies = new Cookies();
  
  
    const navigate = useNavigate();
    const renderInputField = (
      label,
      name,
      value,
      placeholder,
      handleChange,
      touched,
      error,
      type
    ) => {
      return (
        <FormControl variant="standard" sx={{ marginTop: "4px" }} fullWidth>
          <CustomTypography>{label}</CustomTypography>
          <CustomInput
            error={Boolean(touched && error)}
            name={name}
            value={value}
            type={type ? type : "text"}
            onChange={handleChange}
            placeholder={placeholder}
            size="small"
          />
          <FormHelperText error sx={{ fontFamily: "Inter" }}>
            {touched && error}
          </FormHelperText>
        </FormControl>
      );
    };
  
    const renderTitle = (title, description) => {
      return (
        <Box
          alignItems={"center"}
          mb={3}
          display={"flex"}
          flexDirection={"column"}
        >
          <img
            // style={{ marginBottom: "1rem" }}
            src={Logo}
            width={300}
            alt="logo"
          />
       
          <CustomTypography sx={{ color: "#475467", fontSize:'1rem' }}>
            {description}
          </CustomTypography>
        </Box>
      );
    };
  
    const handleClose=(()=>{
      setOpen(false)
    })
  
    const handleOnSubmit =async (values) => {
     try {
      const data = {
        "password":values.password,
        "email": values.userName
      }
      await loginApi.login(data).then((res) => { 
        setLoading(false)
        if (res && res.status === 200) {
          setOpen(true);
          setSeverity('success')
          setMessage('Login Success')
          cookies.set('token', res.data.token);
          const decreptedData = crypto.decryption(res.data.data)
          console.log(decreptedData)
          props.setToken({ token: {
            email:data.email
          }});  
          const auth = {loggedIn : true}
          props.loginSuccess({auth:auth})
          setTimeout(() => {
            navigate('/dashboard')
          }, 1500);
        } else {
          setOpen(true)
          setSeverity('error')
          setMessage(res.data.message)
        }
      });
  } catch (error) {
    console.log(error)
    setLoading(false)
      setOpen(true)
      setSeverity('error')
      setMessage('Login Failed')
  }
    
    };
  
  
   
    return (
      <Root>
        <Page>
          <Box sx={{background:'#EEF4FC'}}>
        <Container maxWidth="sm" className={classes.container}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "1px 1px 10px 0px lightGrey",
              borderRadius:'8px',
              height: "65vh",
              paddingTop: "40px",
              overflowY:'scroll',
              scrollbarWidth:'none'
            }}
          >
            <Formik
              initialValues={{
                userName: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                userName: Yup.string().required("Please enter userName"),
                password: Yup.string().required(" Please enter password"),
              })}
              onSubmit={(values) => {
                handleOnSubmit(values);
              }}
            >
              {({
                errors,
                setFieldValue,
                handleChange,
                handleSubmit,
                touched,
                submitCount,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
  
                  <Container maxWidth={"xs"}>
                    {renderTitle("Cloud Zilla", "Admin Panel")}
                    <Box sx={{ minHeight: 300 }}>
                      {renderInputField(
                        "User Name*",
                        "userName",
                        values.userName,
                        "Enter your User Name",
                        handleChange,
                        touched.userName,
                        errors.userName
                      )}
                      
  
                      {renderInputField(
                        "Password*",
                        "password",
                        values.password,
                        "Enter your password",
                        handleChange,
                        touched.password,
                        errors.password,
                        "password"
                      )}
                   
                    </Box>
                    <LoginButton
                      type={"submit"}
                      fullWidth
                      disabled={loading}
                      sx={{ textAlign: "center" }}
                    >
                      Login
                    </LoginButton>
  
                   
                  </Container>
                </form>
              )}
            </Formik>
          </Card>
        </Container>
          </Box>
        <Alert severity={severity} message={message} open={open} handleClose={handleClose} />
        </Page>
      </Root>
    );
  };
  
  const dispatchToProps = (dispatch) => ({
    setToken: (token) => dispatch(setToken(token)),    
    loginSuccess: (auth) => dispatch(loginSuccess(auth))
  });
  
  export default connect(null, dispatchToProps)(SignIn);