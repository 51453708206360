import axios from "axios";
import { helpers } from "../_helpers";


export const dashboardApi = {
  getDashboardData,
};




async function getDashboardData() {
  helpers.setHeader()
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const data = await axios.get(
      `/dashboard `,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}





