import React from "react";
import Page from "../../components/page";
import { Box, Grid } from "@mui/material";
import NormalCard from "./../../components/Cards/NormalCard";
import LineGraph from "./LineGraph";
import PieGraph from "./PieGraph";

const Dashboard = () => {
  return (
    <Page>
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <NormalCard title="Users">
              <Grid container >
                <Grid item  xs={12} md={8}>
                  <LineGraph />
                </Grid>
                <Grid item xs={12} md={4}>
                  <PieGraph />
                </Grid>
              </Grid>
            </NormalCard>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Dashboard;
