/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  Button,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import NavItem from "./NavItem";
import { connect } from "react-redux";
import { logoutSuccess } from "../../../store/bugs";
import { loginApi } from "../../../_services/login";
import Cookies from "universal-cookie";

const PREFIX = 'index';

const classes = {
  mobileDrawer: `${PREFIX}-mobileDrawer`,
  desktopDrawer: `${PREFIX}-desktopDrawer`,
  avatar: `${PREFIX}-avatar`,
  userBg: `${PREFIX}-userBg`,
  name: `${PREFIX}-name`

};

const Root = styled('div')(() => ({
  [`& .${classes.mobileDrawer}`]: {
    width: 256,
  },

  [`& .${classes.desktopDrawer}`]: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },

  [`& .${classes.avatar}`]: {
    cursor: "pointer",
    width: 64,
    height: 64,
    fontSize: "36px",
  },

  [`& .${classes.userBg}`]: {
    backgroundImage: `url('../../../images/home/bg.jpg')`,
    backgroundPosition: `right bottom`,
    backgroundRepeat: "no-repeat",
  },
  [`& .${classes.name}`]: {
    color: 'grey',
    padding: '8px',
    fontWeight: '600'
  }
}));

const cookies = new Cookies();
const user = {
  avatar: "/static/images/avatars/icon-user-default.png",
  jobTitle: "Admin",
  name: "Jyotheesh",
};

const items = [
  {
    href: "/dashboard",
    icon: "dashboard_outlined",
    title: "Dashboard",
    message: "Dashboard",
  },
  {
    href: "/users",
    icon: "people",
    title: "Users",
    message: "Number of users",
  },
  // {
  //   href: "/tickets",
  //   icon: "list_alt_outlined",
  //   title: "Tickets",
  //   message: "Number of tickets",
  // }
];



const NavBar = ({
  onMobileClose,
  openMobile,
  authReducer,
  switchRole,
  fetchArticleDetails,
}) => {

  const location = useLocation();

  const [userMenu, setUserMenu] = useState(items);
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState("");
  const [snackbarTitle, setSnackbarTitle] = React.useState("");
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  const navigate = useNavigate();

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [show, setShow] = useState(false);

  const openShow = () => {
    if (authReducer.auth.user.user_role.length > 1) {
      setShow(true);
    }
  };

  const closeShow = () => {
    setShow(false);
  };

  const submitHandle = (values) => {
    setRole(values.role);
    setRoleDescription(values.description);

    if (values.setDefault) {
      loginApi.setDefaultRole(values.role).then((res) => {
        if (res) {
        }
      });
    }

    loginApi.switchRole(values.role).then((res) => {
      if (res) {
        if (res.status === 200) {
          cookies.set("token", res.data.Jwttoken.token, {
            path: "/",
            expires: new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 180),
          });

          setShow(false);
          setSeverity("success");
          setSnackbarTitle("Account Switched");
          switchRole({ role: values.role, description: values.description });

          setOpen(true);
          if (values.role === "ADMIN") {
            navigate("/app/dashboard", { replace: true });
          } else if (values.role === "SALES") {
            navigate("/app/dashboard", { replace: true });
          } else if (values.role === "OPERATION") {
            navigate("/app/dashboard", { replace: true });
          } else if (values.role === "CHANNEL_PARTNER") {
            navigate("/app/investor-dashboard", { replace: true });
          } else if (values.role === "INVESTER_CLIENT") {
            navigate("/app/investor-dashboard", { replace: true });
          } else {
          }
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Root>
      <Box height="100%" display="flex" flexDirection="column" p={2}>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          p={1}
          className={classes.userBg}
        >
          <Avatar
            className={classes.avatar}
            component={RouterLink}
            alt={""}
            src={user.avatar}
            to="/app/profile"
          />
          <Typography className={classes.name} color="textPrimary" variant="h5">
            {"Admin"}
          </Typography>
        
        </Box>
        <Box p={0} >
          <List>
            {userMenu &&
              userMenu.map((item) => (
                <Tooltip title={item.message} key={item.href} arrow placement="right">
                  <div>
                    <NavItem
                      href={item.href}
                      key={item.title}
                      title={item.title}
                      icon={item.icon}
                      subMenu={item.subMenu}
                    />
                  </div>
                </Tooltip>
              ))}
          </List>
        </Box>
      </Box>
    </Root>
  );



  return (
    (<Root>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          sx={{
            "& .MuiPaper-root": {
              width: 256
            }
          }}
          // classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      {/* <ModalCentered
        title="Select Account"
        open={show}
        toggle={closeShow}
        children={
          <SelectUserAccountForm
            data={
              authReducer.auth
                ? authReducer.auth.user !== null &&
                  authReducer.auth.user.user_role
                : null
            }
            formSubmit={submitHandle}
            defaultRole={role}
          />
        }
      /> */}
      {/* <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      /> */}
      {/* <SessionExpiredModal open={openSessionExpiredModal} /> */}
    </Root>)
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false,
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state,
  };
};
const dispatchToProps = (dispatch) => ({
  logoutSuccess: () => dispatch(logoutSuccess()),
});

export default connect(mapStateToProps, dispatchToProps)(NavBar);
