// App.js
import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { classicTheme } from "./theme";
import routes from "./routes";
import { connect } from "react-redux";
import "./App.css";

const App = (props) => {
  const routing = useRoutes(routes);
  const theme = classicTheme;
  return <ThemeProvider theme={theme}>{routing}</ThemeProvider>;
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
  };
};

export default connect(mapStateToProps)(App);
