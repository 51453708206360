import axios from "axios";
import { crypto } from "../components/Security/crypto";
export const loginApi = {
  login
};

async function login(userData) {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    let encryptedData = crypto.encryption(userData)
    const data = await axios.post(
      `/cloudzilla_login`,
      encryptedData,
      requestOptions
    );
    return data;
  } catch (error) {
    return error.response;
  }
}






