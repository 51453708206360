import { createAction, createReducer } from '@reduxjs/toolkit';

// Action creators
export const bugAdded = createAction("bugAdded");
export const bugRemoved = createAction("bugRemoved");
export const bugResolved = createAction("bugResolved");
export const setToken = createAction("setToken");
export const logoutSuccess = createAction("logoutSuccess");
export const loginSuccess = createAction("loginSuccess")

const initialState = {
    token: null,
    bugs: [],
};

const reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(bugAdded, (state, action) => {
            state.bugs.push({
                description: action.payload.description,
                resolved: false
            });
        })
        .addCase(setToken, (state, action) => {
            state.token = action.payload.token;
        })
        .addCase(loginSuccess, (state, action) => {
            state.auth=action.payload.auth
        })
        .addCase(logoutSuccess, (state, action) => {
            state.auth.loggedIn=false;
            state.token=null
        });

});

export default reducer;
